import React, { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { Box, FormControl, TextField } from '@mui/material';
import { useGetUserData } from '@hooks/useGetUserData';
import { useGetDealListForItem, useGetDealLists } from '@hooks/useDealLists';

const ItemDealLists = ({
  ASIN,
  onChange
}: {
  ASIN: string;
  onChange: (values: number[]) => void;
}) => {
  const { data: user } = useGetUserData();
  const [selectedDealListItems, setSelectedDealListItems] = useState<number[]>(
    []
  );
  const { data: dealListsAvailable, refetch: fetchDealListsAvailable } =
    useGetDealLists();
  const { data: dealListsForItem, refetch: fetchDealListsForItem } =
    useGetDealListForItem(ASIN);

  useEffect(() => {
    fetchDealListsAvailable();
    fetchDealListsForItem();
  }, []);

  useEffect(() => {
    if (dealListsForItem) {
      setSelectedDealListItems(
        dealListsForItem.map((dealList) => dealList.listId)
      );
      onChange(dealListsForItem.map((dealList) => dealList.listId));
    }
  }, [dealListsForItem]);

  if (!user?.isAdmin || !ASIN) {
    return null;
  }

  return (
    <Box display="flex" margin="12px 0px 24px 0px">
      <FormControl variant="standard" fullWidth>
        <Autocomplete<number, true, false, false>
          multiple
          value={selectedDealListItems}
          onChange={(_event, newValue: number[] | null) => {
            setSelectedDealListItems(newValue);
            onChange(newValue || []);
          }}
          options={
            (dealListsAvailable || []).map((dl) => {
              return dl.id;
            }) || []
          }
          getOptionLabel={(option: number) => {
            const dealList = (dealListsAvailable || []).find(
              (dl) => dl.id === option
            );
            if (!dealList) {
              return '';
            }
            return `${dealList.name}`;
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Deal Lists"
              variant="standard"
              data-lpignore="true"
            />
          )}
        />
      </FormControl>
    </Box>
  );
};

export default ItemDealLists;
