/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import axios from 'axios';
import { useMutation, UseQueryResult, useQuery } from 'react-query';
import queryClient from '../queryClient';
import { CouponDetail } from '../types';

export function useUpdateCouponByPromoCode() {
  interface RequestData {
    promoCode: string;
  }

  const mutationFn = async ({ promoCode }: RequestData): Promise<null> => {
    return axios.post(`/api/dashboard/recheck-deals-by-coupon-code`, {
      promoCode
    });
  };

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.refetchQueries('latest-deals-db');
    },
    onError: (...args) => {
      console.error('error updating coupon by promo code', args);
    }
  });
}

export function useGetHasBeenEmailed(promoCode: string): UseQueryResult<{
  hasBeenEmailed: boolean;
}> {
  const queryFn = async (
    promoCode: string
  ): Promise<{
    hasBeenEmailed: boolean;
  }> => {
    const result = await axios.get(
      `/api/dashboard/has-been-emailed?promoCode=${promoCode}`
    );
    return result.data;
  };

  return useQuery<
    {
      hasBeenEmailed: boolean;
    },
    Error
  >(`hasBeenEmailed-${promoCode}`, () => queryFn(promoCode), {
    onError: () => {
      console.error('Something went wrong while fetching the coupon details');
    }
  });
}

export function useGetCouponDetails(): UseQueryResult<CouponDetail[]> {
  const queryFn = async (): Promise<CouponDetail[]> => {
    const result = await axios.get(`/api/get-coupon-details`);
    return result.data;
  };

  return useQuery<CouponDetail[], Error>(`coupon-details`, () => queryFn(), {
    onError: () => {
      console.error('Something went wrong while fetching the coupon details');
    }
  });
}

export function useRedoCouponImage() {
  interface RequestData {
    promoCode: string;
  }

  const mutationFn = async ({ promoCode }: RequestData): Promise<null> => {
    return axios.post(`/api/dashboard/redo-coupon-image`, {
      promoCode
    });
  };

  return useMutation(mutationFn, {
    onSuccess: () => {
      queryClient.refetchQueries('coupon-details');
    },
    onError: (...args) => {
      console.error('error redoing coupon image by promo code', args);
    }
  });
}
