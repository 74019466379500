import React from 'react';
import Alert from '@mui/material/Alert';
import { Box } from '@mui/material';
import { useGetHasBeenEmailed } from '@hooks/useCoupons';

export default function CouponHasBeenEmailed({
  promoCode
}: {
  promoCode: string;
}) {
  const { data } = useGetHasBeenEmailed(promoCode);

  return (
    <Box>
      {data?.hasBeenEmailed ? (
        <Alert severity="warning">Coupon has been emailed previously.</Alert>
      ) : null}
    </Box>
  );
}
