/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import { useMutation, UseQueryResult, useQuery } from 'react-query';
import axios, { AxiosInstance } from 'axios';
import config from '@configFile';
import { SentMailingType } from '../types';

const axiosInstance: AxiosInstance = axios.create({
  baseURL: config.API_PREFIX
});

interface RequestData {
  slug?: string | null;
  ASIN?: string | null;
  promoCode?: string | null;
  subject?: string | null;
  mailingListId?: string | null;
  options?: {
    showVariations?: boolean;
    showVariationsThreshold?: string;
    emailBeginning?: string;
    additionalImages?: string[];
    mainImage?: string;
    additionalPostText?: string;
    scheduleAt?: Date | null;
    otherMailingType?: string | null;
  };
}

export function useSendMailing() {
  const mutationFn = async ({
    ASIN,
    slug,
    promoCode,
    mailingListId,
    subject,
    options
  }: RequestData): Promise<{
    data: {
      hasSentMailing?: boolean;
      thumbnailUrl?: string;
      error?: string;
    };
  }> => {
    return axiosInstance.post(`/api/dashboard/send-mailing`, {
      ASIN,
      slug,
      promoCode,
      subject,
      mailingListId,
      options
    });
  };

  return useMutation(mutationFn, {
    retry: false
    // onError: ({ data }, ...args) => {
    //   console.log('args', args);
    //   // eslint-disable-next-line no-alert
    //   alert(`error sending mailing \n${data?.error}`);
    // }
  });
}

const queryMailingHistory = async ({
  ASIN,
  slug,
  promoCode
}: RequestData): Promise<SentMailingType[]> => {
  const result = await axiosInstance.post(
    `/api/dashboard/mailing-list-history`,
    {
      slug,
      ASIN,
      promoCode
    }
  );
  return result.data;
};

// export function useGetMailingHistory(
//   req: RequestData
// ): UseQueryResult<SentMailingType[]> {
//   return useQuery<SentMailingType[], Error>(
//     `query-mailing-history-${req.ASIN}-${req.slug}-${req.promoCode}}`,
//     () => queryMailingHistory(req),
//     {
//       enabled: false,
//       onError: () => {
//         console.error('Something went wrong while fetching mailing history');
//       }
//     }
//   );
// }

export function useGetMailingHistoryEnabled(
  req: RequestData
): UseQueryResult<SentMailingType[]> {
  return useQuery<SentMailingType[], Error>(
    `query-mailing-history-${req.ASIN}-${req.slug}-${req.promoCode}}`,
    () => queryMailingHistory(req),
    {
      onError: () => {
        console.error('Something went wrong while fetching mailing history');
      }
    }
  );
}
